<template>
	<bokeNav></bokeNav>
<div class="kuang">
	<a-input v-model:value="picinfo" placeholder="请输入图片信息" allow-clear />
	<br/>
	<br/>
	<br/>
	<br/>
	<a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
	  <a-button>
	    <upload-outlined></upload-outlined>
	    Select File
	  </a-button>
	</a-upload>
	<a-button
	    type="primary"
	    :disabled="fileList.length === 0"
	    :loading="uploading"
	    style="margin-top: 16px"
	    @click="handleUpload"
	>
	  {{ uploading ? 'Uploading' : 'Start Upload' }}
	</a-button>
  <br/>
  <br/>
  <a-input-search
        v-model:value="name"
        placeholder="请输入游戏名称(模糊)"
  		enter-button="Search"
  		      size="large"
        @search="SearchByName"
      />
  <br/>
  <br/>
  <a-list item-layout="horizontal" :data-source="gameList">
    <template #renderItem="{ item }">
      <a-list-item>
  					  <template #extra>
  					            <a-image
  					              width="100px"
  					              :src="item.picurl"
  					            />
  					          </template>
  					   <template #actions>
  					            <a-button type="primary" key="list-loadmore-more" @click="getid(item.id)">确认</a-button>
  					          </template>
        <a-list-item-meta
          :description="item.developer"
        >
          <template #title>
            <p>{{ item.name }}</p>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
  <br/>
  <br/>
  <a-input v-model:value="gid" placeholder="请输入游戏id" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="gtinfo" placeholder="请输入动态" allow-clear />
  <br/>
  <br/>
  <a-list item-layout="horizontal" :data-source="picList">
    <template #renderItem="{ item,index}">
      <a-list-item>
  					  <template #extra>
  					            <a-image
  					              width="100px"
  					              :src="item.picurl"
  					            />
  					          </template>
  					   <template #actions>
  					            <a-button type="primary" danger key="list-loadmore-more" @click="delpic(index)">删除</a-button>
  					          </template>
        <a-list-item-meta
        >
          <template #title>
            <p>{{ item.picinfo }}</p>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</div>
  <a-button type="primary" @click="updateGtrends" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
	import {GtrendsAPI,FileAPI,GameAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
		components: {
		  UploadOutlined,},
		setup(){
		  const fileList = ref([]);
		 
		  const uploading = ref(false);
		  const picurl=ref("");
		  const picinfo=ref("");
		  const picList=ref([]);
		  const handleRemove = file => {
		    const index = fileList.value.indexOf(file);
		    const newFileList = fileList.value.slice();
		    newFileList.splice(index, 1);
		    fileList.value = newFileList;
		  };
			
		  const beforeUpload = file => {
				fileList.value = [...fileList.value, file];
				return false;
		  };
			
		  const handleUpload = () => {
			  if(picinfo.value=="")
			  {
			  				  message.error("并未填写图片信息")
			  }
			  else{
		    const formData = new FormData();
		    fileList.value.forEach(file => {
		      formData.append('file', file);
		    });
		    uploading.value = true; // You can use any AJAX library you like
			
		    FileAPI.upPic(formData).then(res=> {
		      picurl.value=res.data;
		      fileList.value = [];
		      if(res.code==1)
		      {
		        message.success("upload success");
				picList.value.push({
					picurl:res.data,
					picinfo:picinfo.value,
					picusrid:'1',
				});
				picinfo.value="";
		      }
		      else
		      {
		        message.error("upload error");
		      }
		    }).finally(()=>{
		      uploading.value = false;
		    });
			}
		  };
			
		  return {
			 
			picList,
			picinfo,
		    picurl,
		    fileList,
		    uploading,
		    handleRemove,
		    beforeUpload,
		    handleUpload,
		  };
		},
	  data(){
	    return {
			allList:[],
			addList:[],
			delList:[],
		 gtid:this.$route.query.gtid,
	     gtinfo:"",
		 gid:'',
		name:"",
		gameList:[]
	    }
	  },
	  methods:{
	 updateGtrends()
	    {
			let i=0;
			for(i=0;i<this.picList.length;i++)
			{
				if("picid" in this.picList[i]==false)
				{
					this.$data.addList.push(this.picList[i]);
				}
			}
	      let data={
			  gtid:this.$data.gtid,
			  gtinfo:this.$data.info,
			  addlist:JSON.stringify(this.$data.addList),
			  dellist:JSON.stringify(this.$data.delList),
			  gid:this.$data.gid,
			  usrid:'1',
			}
	      GtrendsAPI.update(data).then(res=>{
			  console.log(res)
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
		delpic(ind)
		{
			let index=parseInt(ind);
			if("picid" in this.picList[index])
			{
				this.$data.delList.push(this.picList[index].picid);
			}
		this.picList.splice(index,1);
		},
		SearchByName()
		{
			let data={
				name:this.$data.name,
			};
			GameAPI.selectName(data).then(res=>{
				this.$data.gameList=res.data;
			})
		},
		getid(id)
		{
			this.$data.gid=id;
		},
		getGame(id)
		{
			let data={
				id:id
			};
			GameAPI.selectOne(data).then(res=>{
				console.log(res)
				this.$data.gameList=[res.data];
			})
		}
		
	  },
	  
	  created(){
		  let that=this;
		  let data={
			  id:this.$data.gtid,
		  }
		  GtrendsAPI.selectGtrend(data).then(res=>{
			  that.$data.gtinfo=res.data.gtinfo;
			  that.picList=res.data.picList;
			  this.$data.allList=res.data.picList;
			  this.$data.gid=res.data.gid;
			  this.getGame(res.data.gid)
			  
		  })
	  },
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>