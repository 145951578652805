import { createRouter, createWebHashHistory } from 'vue-router'
import Addarticle from "@/views/article/addarticle.vue";
import Index from '@/views/index.vue';
import Updatearticle from "@/views/article/updatearticle.vue";
import Addanime from "@/views/anime/addanime.vue";
import Updateanime from "@/views/anime/updateanime.vue";
import AllAnime from "@/views/anime/allanime.vue";
import Addgame from "@/views/game/addgame.vue";
import AllGame from "@/views/game/allgame.vue";
import Updategame from "@/views/game/updategame.vue";
import AllPic from "@/views/pic/allpic.vue";
import AllArticle from "@/views/article/allarticle.vue";
import AllTrends from "@/views/trends/all.vue";
import AddAnimeRea from "@/views/animerea/addanimerea.vue";
import AllAnimeRea from "@/views/animerea/all.vue";
import UpdateAnimeRea from "@/views/animerea/update.vue";

import AddMusic from "@/views/music/add.vue";
import AllMusic from "@/views/music/all.vue";
import UpdateMusic from "@/views/music/update.vue";

import AddMusicRea from "@/views/musicrea/add.vue";
import AllMusicRea from "@/views/musicrea/all.vue";
import UpdateMusicRea from "@/views/musicrea/update.vue";

import AddProject from "@/views/project/add.vue";
import AllProject from "@/views/project/all.vue";
import UpdateProject from "@/views/project/update.vue";

import AddTalkBy from "@/views/talkby/add.vue";
import AllTalkBy from "@/views/talkby/all.vue";
import UpdateTalkBy from "@/views/talkby/update.vue";

import AddTrends from "@/views/trends/add.vue";
import UpdateTrends from "@/views/trends/update.vue";

import AddGtrends from "@/views/gtrends/add.vue";
import AllGtrends from "@/views/gtrends/all.vue";
import UpdateGtrends from "@/views/gtrends/update.vue";
import Login from "@/views/login.vue";
const routes = [
  {
    path: '/index',
    name: 'index',
    component: Index
  },
  {
    path: '/addarticle',
    name: 'addarticle',
    component: Addarticle
  },
  {
    path: '/updatearticle',
    name: 'updatearticle',
    component: Updatearticle
  },
  {
    path: '/article-all',
    name: 'article-all',
    component: AllArticle
  },
  {
    path: '/addanime',
    name: 'addanime',
    component: Addanime
  },
  {
    path: '/updateanime',
    name: 'updateanime',
    component: Updateanime
  },
  {
    path: '/all-anime',
    name: 'allanime',
    component: AllAnime
  },
  {
    path: '/addgame',
    name: 'addgame',
    component: Addgame
  },
  {
    path: '/updategame',
    name: 'updategame',
    component: Updategame
  },
  {
    path: '/all-game',
    name: 'allgame',
    component: AllGame
  },
  {
    path: '/all-pic',
    name: 'allpic',
    component:AllPic
  },
  {
    path: '/add-animerea',
    name: 'addanimerea',
    component:AddAnimeRea
  },
  {
    path: '/all-animerea',
    name: 'allanimerea',
    component:AllAnimeRea
  },
  {
    path: '/update-animerea',
    name: 'updateanimerea',
    component:UpdateAnimeRea
  },
  {
    path: '/add-music',
    name: 'addmusic',
    component:AddMusic
  },
  {
    path: '/all-music',
    name: 'allmusic',
    component:AllMusic
  },
  {
    path: '/update-music',
    name: 'updatemusic',
    component:UpdateMusic
  },
  {
    path: '/add-musicrea',
    name: 'addmusicrea',
    component:AddMusicRea
  },
  {
    path: '/all-musicrea',
    name: 'allmusicrea',
    component:AllMusicRea
  },
  {
    path: '/update-musicrea',
    name: 'updatemusicrea',
    component:UpdateMusicRea
  },
  {
    path: '/add-project',
    name: 'addproject',
    component:AddProject
  },
  {
    path: '/all-project',
    name: 'allproject',
    component:AllProject
  },
  {
    path: '/update-project',
    name: 'updateproject',
    component:UpdateProject
  },
   {
      path: '/add-talkby',
      name: 'addtalkby',
      component:AddTalkBy
    },
    {
      path: '/all-talkby',
      name: 'alltalkby',
      component:AllTalkBy
    },
    {
      path: '/update-talkby',
      name: 'updatetalkby',
      component:UpdateTalkBy
    },
	 {
	    path: '/add-trends',
	    name: 'addtrends',
	    component:AddTrends
	  },
	  {
	    path: '/all-trends',
	    name: 'alltrends',
	    component:AllTrends
	  },
	  {
	    path: '/update-trends',
	    name: 'updatetrends',
	    component:UpdateTrends
	  },
	  {
	     path: '/add-gtrends',
	     name: 'addgtrends',
	     component:AddGtrends
	   },
	   {
	     path: '/all-gtrends',
	     name: 'allgtrends',
	     component:AllGtrends
	   },
	   {
	     path: '/update-gtrends',
	     name: 'updategtrends',
	     component:UpdateGtrends
	   },
     {
      path: '/',
      name: 'login',
      component:Login
    },
  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
