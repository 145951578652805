<template>
<div class="box">
    <div class="boxleft" >
      <a-menu
          v-model:selectedKeys="selectedKeys"
          mode="horizontal"
          :open-keys="openKeys"
          @openChange="onOpenChange"
      >
        <a-sub-menu key="sub1">
          <template #icon>
            <MailOutlined />
          </template>
          <template #title>文章</template>
          <a-menu-item key="1">
            <router-link to="/addarticle">添加</router-link>
          </a-menu-item>
          <a-menu-item key="2"><router-link to="/article-all">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2">
          <template #icon></template>
          <template #title>
            <AppstoreOutlined />
           图片
          </template>
          <a-menu-item key="3"><router-link to="/all-pic">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub4">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>动漫</template>
          <a-menu-item key="4"><router-link to="/addanime">添加</router-link></a-menu-item>
          <a-menu-item key="5"><router-link to="/all-anime">全部</router-link></a-menu-item>
          
        </a-sub-menu>
        <a-sub-menu key="sub5">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>游戏</template>
          <a-menu-item key="6">
            <router-link to="/addgame">添加</router-link></a-menu-item>
          <a-menu-item key="7">
            <router-link to="/all-game">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub6">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>动漫感悟</template>
          <a-menu-item key="8">
            <router-link to="/add-animerea">添加</router-link></a-menu-item>
          <a-menu-item key="9">
            <router-link to="/all-animerea">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub7">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>音乐</template>
          <a-menu-item key="10">
            <router-link to="/add-music">添加</router-link></a-menu-item>
          <a-menu-item key="11">
            <router-link to="/all-music">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub8">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>音乐感悟</template>
          <a-menu-item key="12">
            <router-link to="/add-musicrea">添加</router-link></a-menu-item>
          <a-menu-item key="13">
            <router-link to="/all-musicrea">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub9">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>项目</template>
          <a-menu-item key="14">
            <router-link to="/add-project">添加</router-link></a-menu-item>
          <a-menu-item key="15">
            <router-link to="/all-project">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub10">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>杂谈</template>
          <a-menu-item key="16">
            <router-link to="/add-talkby">添加</router-link></a-menu-item>
          <a-menu-item key="17">
            <router-link to="/all-talkby">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub11">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>动态</template>
          <a-menu-item key="18">
            <router-link to="/add-trends">添加</router-link></a-menu-item>
         <a-menu-item key="19">
           <router-link to="/all-trends">全部</router-link></a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub12">
          <template #icon>
            <SettingOutlined />
          </template>
          <template #title>游戏动态</template>
          <a-menu-item key="20">
            <router-link to="/add-gtrends">添加</router-link></a-menu-item>
          <a-menu-item key="21">
            <router-link to="/all-gtrends">全部</router-link></a-menu-item>
        </a-sub-menu>
      </a-menu>
      </div>
    </div>
  </template>
  <script>
  import { defineComponent, reactive, toRefs } from 'vue';
  import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';
  export default defineComponent({
    components: {
      MailOutlined,
      AppstoreOutlined,
      SettingOutlined,
    },
  
    setup() {
      const state = reactive({
       
      });
  
      const onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);
  
        if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          state.openKeys = openKeys;
        } else {
          state.openKeys = latestOpenKey ? [latestOpenKey] : [];
        }
      };
  
      return { ...toRefs(state),
        onOpenChange,
      };
    },
  });
  </script>
  <style>
  .box{
    display: flex;
  
  }
  .boxleft{
    float: left;
  }
  .box-right{
      width: 70vw;;
    float: left;
  }
  
  </style>
  