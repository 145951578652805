<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
    <a-button>
      <upload-outlined></upload-outlined>
      Select File
    </a-button>
  </a-upload>
  <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
  >
    {{ uploading ? 'Uploading' : 'Start Upload' }}
  </a-button>
  <br/>
   <a-image width="100px" :src="picurl" style="float:right"></a-image>
  <br/>
  <a-input v-model:value="name" placeholder="请输入游戏名称" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="url" placeholder="请输入游戏官网链接" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="info" placeholder="请输入游戏背景" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="type" placeholder="请输入游戏类型" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="developer" placeholder="请输入开发者" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="time" placeholder="请输入上市时间" allow-clear />
</div>
  <a-button type="primary" @click="addGame" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
	import {GameAPI,FileAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
	  components: { 
	    UploadOutlined,},
	  setup(){
	    const fileList = ref([]);
	    const uploading = ref(false);
	    const picurl=ref("");
	
	    const handleRemove = file => {
	      const index = fileList.value.indexOf(file);
	      const newFileList = fileList.value.slice();
	      newFileList.splice(index, 1);
	      fileList.value = newFileList;
	    };
	
	    const beforeUpload = file => {
	      fileList.value = [...fileList.value, file];
	      return false;
	    };
	
	    const handleUpload = () => {
	      const formData = new FormData();
	      fileList.value.forEach(file => {
	        formData.append('file', file);
	      });
	      uploading.value = true; // You can use any AJAX library you like
	
	      FileAPI.upPic(formData).then(res=> {
	        picurl.value=res.data;
	        fileList.value = [];
	        if(res.code==1)
	        {
	          message.success("upload success");
	        }
	        else
	        {
	          message.error("upload error");
	        }
	      }).finally(()=>{
	        uploading.value = false;
	      });
	    };
	
	    return {
	      picurl,
	      fileList,
	      uploading,
	      handleRemove,
	      beforeUpload,
	      handleUpload,
	    };
	  },
	  data(){
	    return {
	      name:"",
		  info:'',
		  url:'',
		  type:"",
		  developer:"",
		  time:"",
	    }
	  },
	  methods:{
	    addGame()
	    {
	      let data={
	        picurl:this.picurl,
	        name:this.$data.name,
			info:this.$data.info,
			url:this.$data.url,
			type:this.$data.type,
			developer:this.$data.developer,
			time:this.$data.time,
	      }
	      GameAPI.add(data).then(res=>{
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
	  }
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>