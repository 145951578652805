<template>
<bokeNav></bokeNav>
<div class="kuang">
	<a-input v-model:value="picinfo" placeholder="请输入图片信息" allow-clear />
	<br/>
	<br/>
	<br/>
	<br/>
	<a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
	  <a-button>
	    <upload-outlined></upload-outlined>
	    Select File
	  </a-button>
	</a-upload>
	<a-button
	    type="primary"
	    :disabled="fileList.length === 0"
	    :loading="uploading"
	    style="margin-top: 16px"
	    @click="handleUpload"
	>
	  {{ uploading ? 'Uploading' : 'Start Upload' }}
	</a-button>
  <br/>
  <br/>
  <a-textarea v-model:value="info" placeholder="请输入动态" allow-clear />
  <br/>
  <br/>
  <a-list item-layout="horizontal" :data-source="picList">
    <template #renderItem="{ item,index}">
      <a-list-item>
  					  <template #extra>
  					            <a-image
  					              width="100px"
  					              :src="item.picurl"
  					            />
  					          </template>
  					   <template #actions>
  					            <a-button type="primary" danger key="list-loadmore-more" @click="delpic(index)">删除</a-button>
  					          </template>
        <a-list-item-meta
        >
          <template #title>
            <p>{{ item.picinfo }}</p>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</div>
  <a-button type="primary" @click="updateTrends" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>

</template>

<script>
	import {TrendsAPI,FileAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
		components: {
		  UploadOutlined,},
		setup(){
		  const fileList = ref([]);
		 
		  const uploading = ref(false);
		  const picurl=ref("");
		  const picinfo=ref("");
		  const picList=ref([]);
		  const handleRemove = file => {
		    const index = fileList.value.indexOf(file);
		    const newFileList = fileList.value.slice();
		    newFileList.splice(index, 1);
		    fileList.value = newFileList;
		  };
			
		  const beforeUpload = file => {
				fileList.value = [...fileList.value, file];
				return false;
		  };
			
		  const handleUpload = () => {
			  if(picinfo.value=="")
			  {
			  				  message.error("并未填写图片信息")
			  }
			  else{
		    const formData = new FormData();
		    fileList.value.forEach(file => {
		      formData.append('file', file);
		    });
		    uploading.value = true; // You can use any AJAX library you like
			
		    FileAPI.upPic(formData).then(res=> {
		      picurl.value=res.data;
		      fileList.value = [];
		      if(res.code==1)
		      {
		        message.success("upload success");
				picList.value.push({
					picurl:res.data,
					picinfo:picinfo.value,
					picusrid:'1',
				});
				picinfo.value="";
		      }
		      else
		      {
		        message.error("upload error");
		      }
		    }).finally(()=>{
		      uploading.value = false;
		    });
			}
		  };
			
		  return {
			 
			picList,
			picinfo,
		    picurl,
		    fileList,
		    uploading,
		    handleRemove,
		    beforeUpload,
		    handleUpload,
		  };
		},
	  data(){
	    return {
			allList:[],
			addList:[],
			delList:[],
		 id:this.$route.query.id,
	     info:"",
	    }
	  },
	  methods:{
	 updateTrends()
	    {
			let i=0;
			for(i=0;i<this.picList.length;i++)
			{
				if("picid" in this.picList[i]==false)
				{
					this.$data.addList.push(this.picList[i]);
				}
			}
	      let data={
			  id:this.$data.id,
			  info:this.$data.info,
			  addlist:JSON.stringify(this.$data.addList),
			  dellist:JSON.stringify(this.$data.delList),
			 
			  usrid:'1',
			}
	      TrendsAPI.update(data).then(res=>{
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
		delpic(ind)
		{
			let index=parseInt(ind);
			if("picid" in this.picList[index])
			{
				this.$data.delList.push(this.picList[index].picid);
			}
		this.picList.splice(index,1);
		},
		
	  },
	  created(){
		  let that=this;
		  let data={
			  id:this.$data.id,
		  }
		  TrendsAPI.selectTrend(data).then(res=>{
			  that.$data.info=res.data.info;
			  that.picList=res.data.picList;
			  this.$data.allList=res.data.picList;
		  })
	  },
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>