<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
    <a-button>
      <upload-outlined></upload-outlined>
      Select File
    </a-button>
  </a-upload>
  <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
  >
    {{ uploading ? 'Uploading' : 'Start Upload' }}
  </a-button>
  <br/>
   <a-image width="100px" :src="picurl" style="float:right"></a-image>
  <br/>
  <a-input v-model:value="tag" placeholder="请输入标签信息，以/分开" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="title" placeholder="请输入文章标题" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="major" placeholder="请输入文章概要" allow-clear />
  <br/>
  <br/>
  <md-editor  v-model="text"  @onUploadImg="onUploadImg" />
</div>
  <a-button type="primary" @click="addarticle" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
import {ArticleAPI,FileAPI} from '@/api/api';
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import axios from "axios";

export default defineComponent({
  name:"addarticle",
  components: { MdEditor,
    UploadOutlined,},
  setup(){
    const fileList = ref([]);
    const uploading = ref(false);
    const picurl=ref("");

    const handleRemove = file => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const beforeUpload = file => {
      fileList.value = [...fileList.value, file];
      return false;
    };

    const handleUpload = () => {
      const formData = new FormData();
      fileList.value.forEach(file => {
        formData.append('file', file);
      });
      uploading.value = true; // You can use any AJAX library you like

      FileAPI.upPic(formData).then(res=> {
        console.log(res.data);
        picurl.value=res.data;
        fileList.value = [];
        if(res.code==1)
        {
          message.success("upload success");
        }
        else
        {
          message.error("upload error");
        }
      }).finally(()=>{
        uploading.value = false;
      });
    };

    return {
      picurl,
      fileList,
      uploading,
      handleRemove,
      beforeUpload,
      handleUpload,
    };
  },
  data(){
    return {
      text:'',
      major:'',
      title:'',
      pcurl:'',
      tag:'',
    }
  },
  methods:{
    addarticle()
    {
      let data={
        picurl:this.picurl,
        title:this.$data.title,
        major:this.$data.major,
        info:this.$data.text,
        tag:this.$data.tag,
		usrid:'1',
      }
      ArticleAPI.add(data).then(res=>{
		  if(res.data=="success")
		  {
			   message.success("submit success");
		  }

      }).catch(err=>{
        alert(err);
      })


    },
    async onUploadImg(files,callback) {
     const res =await Promise.all(
         Array.from(files).map((file) => {
           return new Promise((rev, rej) => {
             const form = new FormData();
             form.append('file', file);
     
             axios.post('/file/picupload', form, {
                 headers: {
                   'Content-Type': 'multipart/form-data'
                 }
               })
               .then((res) => rev(res))
               .catch((error) => rej(error));
           });
         }) 
       );
	   callback(res.map((item) => item.data.data));
    }, 
  }
});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>