<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
    <a-button>
      <upload-outlined></upload-outlined>
      选择歌曲封面
    </a-button>
  </a-upload>
  <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
  >
    {{ uploading ? 'Uploading' : 'Start Upload' }}
  </a-button>
  <br/>
   <a-image width="100px" :src="picurl" style="float:right"></a-image>
  <br/>
  <a-upload :file-list="urlList" :before-upload="beforeUploadUrl" @remove="handleRemoveUrl">
    <a-button>
      <upload-outlined></upload-outlined>
      选择歌曲MP3
    </a-button>
  </a-upload>
  <a-button
      type="primary"
      :disabled="urlList.length === 0"
      :loading="uploadingUrl"
      style="margin-top: 16px"
      @click="handleUploadUrl"
  >
    {{ uploadingUrl ? 'Uploading' : 'Start Upload' }}
  </a-button>
  <br/>
  <br/>
  <a-input v-model:value="url" placeholder="请输入mp3链接" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="lrc" placeholder="请输入歌词(字符串)" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="name" placeholder="请输入歌曲名称" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="artist" placeholder="请输入演唱者" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="theme" placeholder="请输入主题色" allow-clear />
</div>
  <a-button type="primary" @click="addMusic" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
	import {MusicAPI,FileAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
	  components: { 
	    UploadOutlined,},
	  setup(){
	    const fileList = ref([]);
	    const uploading = ref(false);
	    const picurl=ref("");
		const urlList = ref([]);
		const uploadingUrl= ref(false);
		const url=ref("");
		const lrc=ref("");
	
	    const handleRemove = file => {
	      const index = fileList.value.indexOf(file);
	      const newFileList = fileList.value.slice();
	      newFileList.splice(index, 1);
	      fileList.value = newFileList;
	    };
		const handleRemoveUrl = file => {
		  const index = urlList.value.indexOf(file);
		  const newFileList = urlList.value.slice();
		  newFileList.splice(index, 1);
		  urlList.value = newFileList;
		};
	
	    const beforeUpload = file => {
	      fileList.value = [...fileList.value, file];
	      return false;
	    };
		const beforeUploadUrl = file => {
		  urlList.value = [...urlList.value, file];
		  return false;
		};
	
	    const handleUpload = () => {
	      const formData = new FormData();
	      fileList.value.forEach(file => {
	        formData.append('file', file);
	      });
	      uploading.value = true; // You can use any AJAX library you like
	
	      FileAPI.upPic(formData).then(res=> {
	        picurl.value=res.data;
	        fileList.value = [];
	        if(res.code==1)
	        {
	          message.success("upload success");
	        }
	        else
	        {
	          message.error("upload error");
	        }
	      }).finally(()=>{
	        uploading.value = false;
	      });
	    };
		const handleUploadUrl = () => {
		  const formData = new FormData();
		  urlList.value.forEach(file => {
		    formData.append('file', file);
		  });
		  uploadingUrl.value = true; // You can use any AJAX library you like
			
		  FileAPI.upFile(formData).then(res=> {
		    url.value=res.data;
		    urlList.value = [];
		    if(res.code==1)
		    {
		      message.success("upload success");
		    }
		    else
		    {
		      message.error("upload error");
		    }
		  }).finally(()=>{
		    uploadingUrl.value = false;
		  });
		};
	
	    return {
			lrc,
			url,
			urlList,
			uploadingUrl,
			handleRemoveUrl,
			beforeUploadUrl,
			handleUploadUrl,
	      picurl,
	      fileList,
	      uploading,
	      handleRemove,
	      beforeUpload,
	      handleUpload,
	    };
	  },
	  data(){
	    return {
			id:this.$route.query.id,
	      name:"",
		  artist:"",
		  theme:"#1E90FF",
	    }
	  },
	  methods:{
	    addMusic()
	    {
	      let data={
			  id:this.$data.id,
	        cover:this.picurl,
	        name:this.$data.name,
			artist:this.$data.artist,
			url:this.url,
			theme:this.$data.theme,
			lrc:this.lrc,
	      }
	      MusicAPI.update(data).then(res=>{
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
	  },
	  created()
	  {
		  let data={
			  id:this.$data.id
		  }
		  MusicAPI.selectOne(data).then(res=>{
			  this.$data.name=res.data.name;
			  this.$data.artist=res.data.artist;
			  this.$data.theme=res.data.theme;
			  this.picurl=res.data.cover;
			  this.url=res.data.url;
			  this.lrc=res.data.lrc;
		  })
	  }
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>