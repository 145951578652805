<template>
		<bokeNav></bokeNav>
	 <a-modal v-model:visible="visible" title="删除确认" @ok="handleOk">
	      <p>{{deltitle}}将被删除！</p>
	    </a-modal>
	<div class="box-art">
		<div>
			  <a-list item-layout="horizontal" :data-source="picList">
			    <template #renderItem="{ item }">
			      <a-list-item>
					  <template #extra>
					            <a-image
					              width="100px"
					              :src="item.picurl"
					            />
					          </template>
					   <template #actions>
					            <a-button type="primary" danger key="list-loadmore-more" @click="showModal(item.picid,item.picinfo)">删除</a-button>
					          </template>
			        <a-list-item-meta
			          :description="item.pictime"
			        >
			          <template #title>
			            <p>{{ item.picinfo }}</p>
			          </template>
			        </a-list-item-meta>
			      </a-list-item>
			    </template>
			  </a-list>
		</div>
		<a-row type="flex" justify="space-around" align="middle">
			<a-col>
			<a-pagination v-model:current="page" :total="pageNum*10" show-less-items @change="getPage"/>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { message } from 'ant-design-vue';
	import {PicAPI} from '@/api/api';
	export default{
		data(){
			return {
				deltitle:"123",
				delid:"0",
				visible:false,
				page:1,
				pageNum:1,
				picList:[
				]
			}
		},
		methods:{
			getAllPic(page)
			{
				let that=this;
				let data={
					page:page,
				}
				PicAPI.selectAll(data).then(res=>{
					that.$data.picList=res.data.list;
					that.$data.page=res.data.pageNum;
					that.$data.pageNum=res.data.pages;
				})
				
			},
			getPage(page,pagesize)
			{
				this.getAllPic(page);
			},
			showModal(id,title)
			{
				this.$data.deltitle=title;
				this.$data.delid=id;
				this.$data.visible=true;
			},
			handleOk()
			{
				let that=this;
			 this.$data.visible=false;
			 let data={
				 id:this.$data.delid,
			 }
			 PicAPI.delete(data).then(res=>{
				 if(res.data=="success")
				 {
					 message.success("删除成功！");
					 that.getAllPic(1);
				 }
			 })
			}
		},
		created(){
			this.getAllPic(1);
		},
	}
</script>

<style>
	.box-art{
		margin: 5vh 5vw 5vh 5vw;
	}
	.box-right{
      width: 70vw;;
    float: left;
  }
</style>