<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <a-input v-model:value="name" placeholder="请输入项目名称" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="tech" placeholder="请输入运用到的技术" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="honor" placeholder="请输入获取的奖项" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="status" placeholder="请输入运行状态(0-停止,1-运行)" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="url" placeholder="请输入源码链接" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="worth" placeholder="请输入项目意义" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="type" placeholder="请输入项目类型" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="developer" placeholder="请输入开发者" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="timef" placeholder="请输入完成时间" allow-clear />
</div>
  <a-button type="primary" @click="updateProject" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
	import {ProjectAPI,FileAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
	  data(){
	    return {
			id:this.$route.query.id,
	      name:"",
		  worth:'',
		  tech:"",
		  status:0,
		  honor:"",
		  url:'',
		  type:"",
		  developer:"",
		  timef:"",
	    }
	  },
	  methods:{
	    updateProject()
	    {
	      let data={
			  id:this.$data.id,
			  tech:this.$data.tech,
			  honor:this.$data.honor,
			  status:parseInt(this.$data.status),
	        name:this.$data.name,
			worth:this.$data.worth,
			url:this.$data.url,
			type:this.$data.type,
			developer:this.$data.developer,
			timef:this.$data.timef,
	      }
	      ProjectAPI.update(data).then(res=>{
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
	  },
	  created(){
		  let data={
			  id:this.$data.id
		  }
		  ProjectAPI.selectOne(data).then(res=>{
			  this.$data.name=res.data.name;
			  this.$data.worth=res.data.worth;
			  this.$data.tech=res.data.tech;
			  this.$data.status=res.data.status;
			  this.$data.honor=res.data.honor;
			  this.$data.url=res.data.url;
			  this.$data.type=res.data.type;
			  this.$data.developer=res.data.developer;
			  this.$data.timef=res.data.timef;
			  
		  })
	  }
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>