import{post,upFile} from '@/api/request';
//文件处理类
export class FileAPI{
	static upFile(data)
	{
		return upFile("/file/bokeupload",data);
	}
	static upPic(data)
	{
		return upFile("/file/picupload",data);
	}
}
//文章类
export class ArticleAPI{
	static add(data)
	{
		return post("/api/article/add",data);
	}
	static delete(data)
	{
		return post("/api/article/del",data);
	}
	static update(data)
	{
		return post("/api/article/update",data);
	}
	static selectOne(data)
	{
		return post("/api/article/one",data);
	}
	static selectAll(data)
	{
		return post("/api/article/all",data);
	}
}
//动漫类
export class AnimeAPI{
	static add(data)
	{
		return post("/api/anime/add",data);
	}
	static delete(data)
	{
		return post("/api/anime/del",data);
	}
	static update(data)
	{
		return post("/api/anime/update",data);
	}
	static selectOne(data)
	{
		return post("/api/anime/one",data);
	}
	static selectAll(data)
	{
		return post("/api/anime/all",data);
	}
	static selectByName(data)
	{
		return post("/api/anime/byname",data);
	}
}
//动漫感悟类
export class AnimeReaAPI{
	static add(data)
	{
		return post("/api/animerea/add",data);
	}
	static delete(data)
	{
		return post("/api/animerea/delete",data);
	}
	static update(data)
	{
		return post("/api/animerea/update",data);
	}
	static selectOne(data)
	{
		return post("/api/animerea/selectone",data);
	}
	static selectAll(data)
	{
		return post("/api/animerea/selectall",data);
	}
}
//游戏类
export class GameAPI{
	static add(data)
		{
			return post("/api/game/add",data);
		}
		static delete(data)
		{
			return post("/api/game/del",data);
		}
		static update(data)
		{
			return post("/api/game/update",data);
		}
		static selectOne(data)
		{
			return post("/api/game/one",data);
		}
		static selectAll(data)
		{
			return post("/api/game/all",data);
		}
		static selectName(data)
		{
			return post("/api/game/name",data);
		}
}
//音乐类
export class MusicAPI{
	static add(data)
		{
			return post("/api/music/add",data);
		}
		static delete(data)
		{
			return post("/api/music/delete",data);
		}
		static update(data)
		{
			return post("/api/music/update",data);
		}
		static selectOne(data)
		{
			return post("/api/music/selectone",data);
		}
		static selectAll(data)
		{
			return post("/api/music/selectall",data);
		}
		static selectByName(data)
		{
			return post("/api/music/byname",data);
		}
}
//音乐感悟
export class MusicReaAPI {
	static add(data)
		{
			return post("/api/musicrea/add",data);
		}
		static delete(data)
		{
			return post("/api/musicrea/delete",data);
		}
		static update(data)
		{
			return post("/api/musicrea/update",data);
		}
		static selectOne(data)
		{
			return post("/api/musicrea/selectone",data);
		}
		static selectAll(data)
		{
			return post("/api/musicrea/selectall",data);
		}
}
//工程
export class ProjectAPI
{
	static add(data)
		{
			return post("/api/project/add",data);
		}
		static delete(data)
		{
			return post("/api/project/delete",data);
		}
		static update(data)
		{
			return post("/api/project/update",data);
		}
		static selectOne(data)
		{
			return post("/api/project/selectone",data);
		}
		static selectAll(data)
		{
			return post("/api/project/selectall",data);
		}
}
//杂谈类
export class TalkByAPI
{
	static add(data)
		{
			return post("/api/talkby/add",data);
		}
		static delete(data)
		{
			return post("/api/talkby/delete",data);
		}
		static update(data)
		{
			return post("/api/talkby/update",data);
		}
		static selectOne(data)
		{
			return post("/api/talkby/selectone",data);
		}
		static selectAll(data)
		{
			return post("/api/talkby/selectall",data);
		}
}
//动态类
export class TrendsAPI{
	static add(data)
		{
			return post("/api/trends/add",data);
		}
		static delete(data)
		{
			return post("/api/trends/delete",data);
		}
		static update(data)
		{
			return post("/api/trends/update",data);
		}
		static selectOne(data)
		{
			return post("/api/trends/selectone",data);
		}
		static selectAll(data)
		{
			return post("/api/trends/selectall",data);
		}
		static selectTrend(data)
		{
			return post("/api/trends/trend",data);
		}
}
export class PicAPI{
	static add(data)
			{
				return post("/api/pic/add",data);
			}
			static delete(data)
			{
				return post("/api/pic/del",data);
			}
			static update(data)
			{
				return post("/api/pic/update",data);
			}
			static selectOne(data)
			{
				return post("/api/pic/one",data);
			}
			static selectAll(data)
			{
				return post("/api/pic/all",data);
			}
	
}
export class GtrendsAPI{
	static add(data)
		{
			return post("/api/gtrends/add",data);
		}
		static delete(data)
		{
			return post("/api/gtrends/delete",data);
		}
		static update(data)
		{
			return post("/api/gtrends/update",data);
		}
		static selectOne(data)
		{
			return post("/api/gtrends/selectone",data);
		}
		static selectAll(data)
		{
			return post("/api/gtrends/selectall",data);
		}
		static selectGtrend(data)
		{
			return post("/api/gtrends/gtrend",data);
		}
		static selectGame(data)
		{
			return post("/api/gtrends/game",data);
		}
	
}
export class UsrAPI
{
	static add(data)
	{
		return post("/api/usr/add",data);
	}
}