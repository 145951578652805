<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
    <a-button>
      <upload-outlined></upload-outlined>
      Select File
    </a-button>
  </a-upload>
  <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
  >
    {{ uploading ? 'Uploading' : 'Start Upload' }}
  </a-button>
  <br/>
   <a-image width="100px" :src="picurl" style="float:right"></a-image>
  <br/>
   <a-input-search
        v-model:value="name"
        placeholder="请输入番剧名称(模糊)"
		enter-button="Search"
		      size="large"
        @search="SearchByName"
      />
  <br/>
  <br/>
  <a-list item-layout="horizontal" :data-source="animeList">
    <template #renderItem="{ item }">
      <a-list-item>
  					  <template #extra>
  					            <a-image
  					              width="100px"
  					              :src="item.apicurl"
  					            />
  					          </template>
  					   <template #actions>
  					            <a-button type="primary" key="list-loadmore-more" @click="getid(item.aid)">确认</a-button>
  					          </template>
        <a-list-item-meta
          :description="item.status"
        >
          <template #title>
            <p>{{ item.aname }}</p>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
  <br/>
  <br/>
  <a-input v-model:value="aid" placeholder="请输入番剧id" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="rtitle" placeholder="请输入感悟标题" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="rmajor" placeholder="请输入感悟概要" allow-clear />
  <br/>
  <br/>
  <md-editor  v-model="text" @onHtmlChanged="onHtmlChanged" @onUploadImg="onUploadImg" />
</div>
  <a-button type="primary" @click="updateanimeRea" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
import {AnimeReaAPI,FileAPI,AnimeAPI} from '@/api/api';
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import axios from "axios";

export default defineComponent({
  components: { MdEditor,
    UploadOutlined,},
  setup(){
    const fileList = ref([]);
    const uploading = ref(false);
    const picurl=ref("");

    const handleRemove = file => {
      const index = fileList.value.indexOf(file);
      const newFileList = fileList.value.slice();
      newFileList.splice(index, 1);
      fileList.value = newFileList;
    };

    const beforeUpload = file => {
      fileList.value = [...fileList.value, file];
      return false;
    };

    const handleUpload = () => {
      const formData = new FormData();
      fileList.value.forEach(file => {
        formData.append('file', file);
      });
      uploading.value = true; // You can use any AJAX library you like

      FileAPI.upPic(formData).then(res=> {
        picurl.value=res.data;
        fileList.value = [];
        if(res.code==1)
        {
          message.success("upload success");
        }
        else
        {
          message.error("upload error");
        }
      }).finally(()=>{
        uploading.value = false;
      });
    };

    return {
      picurl,
      fileList,
      uploading,
      handleRemove,
      beforeUpload,
      handleUpload,
    };
  },
  data(){
    return {
		rid:this.$route.query.rid,
		animeList:[],
      text:'',
      rmajor:'',
      rtitle:'',
      name:"",
	  aid:"",
    }
  },
  methods:{
    updateanimeRea()
    {
      let data={
		  rid:this.$data.rid,
        rpicurl:this.picurl,
        rtitle:this.$data.rtitle,
        rmajor:this.$data.rmajor,
        rinfo:this.$data.text,
        aid:this.$data.aid,
		rusrid:'1',
      }
      AnimeReaAPI.update(data).then(res=>{
		  if(res.data=="success")
		  {
			   message.success("submit success");
		  }

      }).catch(err=>{
        alert(err);
      })


    },
    async onUploadImg(files,callback) {
     const res =await Promise.all(
         Array.from(files).map((file) => {
           return new Promise((rev, rej) => {
             const form = new FormData();
             form.append('file', file);
     
             axios.post('/file/picupload', form, {
                 headers: {
                   'Content-Type': 'multipart/form-data'
                 }
               })
               .then((res) => rev(res))
               .catch((error) => rej(error));
           });
         }) 
       );
	   callback(res.map((item) => item.data.data));
    }, 
	SearchByName()
	{
		let data={
			aname:this.$data.name,
		}
		AnimeAPI.selectByName(data).then(res=>{
			this.$data.animeList=res.data;
			
		})
	},
	getid(id)
	{
		this.$data.aid=id;
	},
	getAnime(id)
	{
		let data={
			aid:id,
		}
		AnimeAPI.selectOne(data).then(res=>{
					  this.$data.animeList=[res.data];	  
		})
	}
  },
  created()
  {
	  let data={
		  rid:this.$data.rid
	  }
	  AnimeReaAPI.selectOne(data).then(res=>{
		  this.$data.aid=res.data.aid;
		  this.$data.text=res.data.rinfo;
		  this.$data.rmajor=res.data.rmajor;
		  this.$data.rtitle=res.data.rtitle;
		  this.picurl=res.data.rpicurl;
		  this.getAnime(res.data.aid);
	  })
	  
  }
});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }

</style>