<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
    <a-button>
      <upload-outlined></upload-outlined>
      Select File
    </a-button>
  </a-upload>
  <a-button
      type="primary"
      :disabled="fileList.length === 0"
      :loading="uploading"
      style="margin-top: 16px"
      @click="handleUpload"
  >
    {{ uploading ? 'Uploading' : 'Start Upload' }}
  </a-button>
  <br/>
   <a-image width="100px" :src="picurl" style="float:right"></a-image>
  <br/>
  <a-input v-model:value="aname" placeholder="请输入番剧名称" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="aurl" placeholder="请输入番剧链接" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="ainfo" placeholder="请输入番剧概要" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="atime" placeholder="请输入开播时间" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="fulltime" placeholder="请输入更新时间" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="aend" placeholder="请输入是否完结" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="aweek" placeholder="请输入更新星期(0~6)" allow-clear />
</div>
  <a-button type="primary" @click="addAnime" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
	import {AnimeAPI,FileAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
	  components: { 
	    UploadOutlined,},
	  setup(){
	    const fileList = ref([]);
	    const uploading = ref(false);
	    const picurl=ref("");
	
	    const handleRemove = file => {
	      const index = fileList.value.indexOf(file);
	      const newFileList = fileList.value.slice();
	      newFileList.splice(index, 1);
	      fileList.value = newFileList;
	    };
	
	    const beforeUpload = file => {
	      fileList.value = [...fileList.value, file];
	      return false;
	    };
	
	    const handleUpload = () => {
	      const formData = new FormData();
	      fileList.value.forEach(file => {
	        formData.append('file', file);
	      });
	      uploading.value = true; // You can use any AJAX library you like
	
	      FileAPI.upPic(formData).then(res=> {
	        picurl.value=res.data;
	        fileList.value = [];
	        if(res.code==1)
	        {
	          message.success("upload success");
	        }
	        else
	        {
	          message.error("upload error");
	        }
	      }).finally(()=>{
	        uploading.value = false;
	      });
	    };
	
	    return {
	      picurl,
	      fileList,
	      uploading,
	      handleRemove,
	      beforeUpload,
	      handleUpload,
	    };
	  },
	  data(){
	    return {
		  atime:"",
	      aname:"",
		  ainfo:'',
		  aurl:'',
		  fulltime:"8:00",
		  aend:0,
		  aweek:0,
	    }
	  },
	  methods:{
	    addAnime()
	    {
	      let data={
            atime:this.$data.atime,
	        apicurl:this.picurl,
	        aname:this.$data.aname,
			ainfo:this.$data.ainfo,
			aurl:this.$data.aurl,
			fulltime:this.$data.fulltime,
			aend:parseInt(this.$data.aend),
			aweek:parseInt(this.$data.aweek),
	      }
	      AnimeAPI.add(data).then(res=>{
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
	  }
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>