<template>
    <div class="login-border">
        <a-input-password v-model:value="password" placeholder="input password" />
        <a-button class="login-btn" type="primary" shape="round" size="large" @click="comparePwd">
    登录
  </a-button>
    </div>
</template>
<script>
    import { message } from 'ant-design-vue';
    import { notification } from 'ant-design-vue';
    const bcryptjs=require('bcryptjs');
    export default({
        data(){
            let password;
            return{
                password,

            }
        },
        methods: {
            comparePwd()
            {
                let password=this.$data.password;
                let secretKey="$2a$10$NCj10PijTHMWdmks2BbyAePBB30noOaWBSIy8VxpaPj75Exb24C56";
                let testKey=bcryptjs.compareSync(password,secretKey);
                if(testKey==true)
                {
                    this.getNotice();
                    this.$router.push({
					path:"/index"
				});
                }
                else
                {
                    message.error("密码错误！");
                }
            },
            getNotice(){
        notification.open({
        message: '登录成功！',
        description: '你已正确填写密码通过了验证！',
      });

            }
            
        },
    })

</script>
<style scoped>
    .login-border{
        width: 50vw;
        margin: 30vh auto;
    }
    .login-btn{
        margin-top: 10vh;
        margin-left: 20vw;

        

    }

</style>