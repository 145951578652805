<template>
	<bokeNav></bokeNav>
<div class="kuang">
	<a-input v-model:value="picinfo" placeholder="请输入图片信息" allow-clear />
	<br/>
	<br/>
	<a-upload :file-list="fileList" :before-upload="beforeUpload" @remove="handleRemove">
	  <a-button>
	    <upload-outlined></upload-outlined>
	    Select File
	  </a-button>
	</a-upload>
	<a-button
	    type="primary"
	    :disabled="fileList.length === 0"
	    :loading="uploading"
	    style="margin-top: 16px"
	    @click="handleUpload"
	>
	  {{ uploading ? 'Uploading' : 'Start Upload' }}
	</a-button>
  <br/>
  <br/>
  <a-textarea v-model:value="info" placeholder="请输入动态" allow-clear />
  <br/>
  <br/>
  <a-list item-layout="horizontal" :data-source="picList">
    <template #renderItem="{ item,index}">
      <a-list-item>
  					  <template #extra>
  					            <a-image
  					              width="100px"
  					              :src="item.picurl"
  					            />
  					          </template>
  					   <template #actions>
  					            <a-button type="primary" danger key="list-loadmore-more" @click="delpic(index)">删除</a-button>
  					          </template>
        <a-list-item-meta
        >
          <template #title>
            <p>{{ item.picinfo }}</p>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</div>
  <a-button type="primary" @click="addTrends" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
	import {TrendsAPI,FileAPI} from '@/api/api';
	import { UploadOutlined } from '@ant-design/icons-vue';
	import { message } from 'ant-design-vue';
	import { defineComponent, ref } from 'vue';
	export default defineComponent({
		components: {
		  UploadOutlined,},
		setup(){
		  const fileList = ref([]);
		  const uploading = ref(false);
		  const picurl=ref("");
		  const picinfo=ref("");
		  const picList=ref([]);
			
		  const handleRemove = file => {
		    const index = fileList.value.indexOf(file);
		    const newFileList = fileList.value.slice();
		    newFileList.splice(index, 1);
		    fileList.value = newFileList;
		  };
			
		  const beforeUpload = file => {
				fileList.value = [...fileList.value, file];
				return false;
		  };
			
		  const handleUpload = () => {
			  if(picinfo.value=="")
			  {
			  				  message.error("并未填写图片信息")
			  }
			  else{
		    const formData = new FormData();
		    fileList.value.forEach(file => {
		      formData.append('file', file);
		    });
		    uploading.value = true; // You can use any AJAX library you like
			
		    FileAPI.upPic(formData).then(res=> {
		      picurl.value=res.data;
		      fileList.value = [];
		      if(res.code==1)
		      {
		        message.success("upload success");
				picList.value.push({
					picurl:res.data,
					picinfo:picinfo.value,
					picusrid:'1',
				})
				picinfo.value="";
		      }
		      else
		      {
		        message.error("upload error");
		      }
		    }).finally(()=>{
		      uploading.value = false;
		    });
			}
		  };
			
		  return {
			picList,
			picinfo,
		    picurl,
		    fileList,
		    uploading,
		    handleRemove,
		    beforeUpload,
		    handleUpload,
		  };
		},
	  data(){
	    return {
	     info:"",
	    }
	  },
	  methods:{
	    addTrends()
	    {

	      let data={
			  info:this.$data.info,
			  picList:JSON.stringify(this.picList), 
			  usrid:'1',
			}
	      TrendsAPI.add(data).then(res=>{
			  console.log(res)
			  if(res.data=="success")
			  {
				   message.success("submit success");
			  }
	
	      }).catch(err=>{
	        alert(err);
	      })
	    },
		delpic(index)
		{
		this.picList.splice(index,1);
		}
	  }
	});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>