<template>
  <bokeNav></bokeNav>
<div style="margin-top:30vh;margin-left: 30vw;">
  <h1>欢迎使用洛天Life博客后台系统</h1>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>