<template>
	<bokeNav></bokeNav>
<div class="kuang">
  <br/>
   <a-input-search
        v-model:value="name"
        placeholder="请输入歌曲名称(模糊)"
		enter-button="Search"
		      size="large"
        @search="SearchByName"
      />
  <br/>
  <br/>
  <a-list item-layout="horizontal" :data-source="musicList">
    <template #renderItem="{ item }">
      <a-list-item>
  					  <template #extra>
  					            <a-image
  					              width="100px"
  					              :src="item.cover"
  					            />
  					          </template>
  					   <template #actions>
  					            <a-button type="primary" key="list-loadmore-more" @click="getid(item.id)">确认</a-button>
  					          </template>
        <a-list-item-meta
          :description="item.artist"
        >
          <template #title>
            <p>{{ item.name }}</p>
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
  <br/>
  <br/>
  <a-input v-model:value="mid" placeholder="请输入歌曲id" allow-clear />
  <br/>
  <br/>
  <a-input v-model:value="title" placeholder="请输入感悟标题" allow-clear />
  <br/>
  <br/>
  <a-textarea v-model:value="major" placeholder="请输入感悟概要" allow-clear />
  <br/>
  <br/>
  <md-editor  v-model="text"  @onUploadImg="onUploadImg" />
</div>
  <a-button type="primary" @click="addMusicRea" style="margin-top: 5vh;margin-left: 25vw;margin-bottom: 10vh;">
    提交
  </a-button>
</template>

<script>
import {MusicReaAPI,FileAPI,MusicAPI} from '@/api/api';
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import { UploadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref } from 'vue';
import axios from "axios";

export default defineComponent({
  components: { MdEditor},
  data(){
    return {
		musicList:[],
      text:'',
      major:'',
      title:'',
      name:"",
	  mid:"",
    }
  },
  methods:{
    addMusicRea()
    {
      let data={
        title:this.$data.title,
        major:this.$data.major,
        info:this.$data.text,
        mid:this.$data.mid,
		usrid:'1',
      };
      MusicReaAPI.add(data).then(res=>{
		  if(res.data=="success")
		  {
			   message.success("submit success");
		  }

      }).catch(err=>{
        alert(err);
      })


    },
    async onUploadImg(files,callback) {
     const res =await Promise.all(
         Array.from(files).map((file) => {
           return new Promise((rev, rej) => {
             const form = new FormData();
             form.append('file', file);
     
             axios.post('/file/picupload', form, {
                 headers: {
                   'Content-Type': 'multipart/form-data'
                 }
               })
               .then((res) => rev(res))
               .catch((error) => rej(error));
           });
         }) 
       );
	   callback(res.map((item) => item.data.data));
    }, 
	SearchByName()
	{
		let data={
			name:this.$data.name,
		}
		MusicAPI.selectByName(data).then(res=>{
			this.$data.musicList=res.data;
			
		})
	},
	getid(id)
	{
		this.$data.mid=id;
	}
  }
});
</script>

<style scoped>
.kuang{
  margin-top: 5vh;
  margin-left: 5vw;
  margin-right: 5vw;
}
.box-right{
      width: 70vw;;
    float: left;
  }
</style>